import React from 'react';
import { Tag } from 'antd';
import { PacType } from 'lib/api/pacApi';
import { IPageData } from '../../pages/App';
import { HeaderItem, HeaderItemNoLink, HeaderItemSpace, HeaderTitleText } from './styled';

interface IBreadCrumbProp {
  pageData?: IPageData;
}
const BreadCrumb = ({ pageData }: IBreadCrumbProp) => {
  return (
    <>
      {!pageData?.pac && (
        <HeaderItem to="/" className="active">
          Overview
        </HeaderItem>
      )}
      {pageData?.pac && (
        <>
          <HeaderItem to="/configurations" className="no-active">
            Configurations
          </HeaderItem>
          <HeaderItemSpace>{'>'}</HeaderItemSpace>
          <HeaderItemNoLink className="active">
            <HeaderTitleText>{pageData?.pac?.title}</HeaderTitleText>{' '}
            {pageData?.pac?.type === PacType.LITE && <Tag color="blue">AUDIENCE UNLOCKER</Tag>}
            {!!pageData?.pac?.deactivated && <Tag color="red">Inactive</Tag>}
          </HeaderItemNoLink>
        </>
      )}
    </>
  );
};

export default BreadCrumb;
