import styled from 'styled-components';

export const GCMBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    padding: 0px 6px;
  }

  & svg {
    margin: 0;
  }
`;

export const GCMTitle = styled.p`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryText};
`;

export const GCMDescription = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.colors.primaryIcons};
  display: flex;
  align-items: center;
  white-space: break-spaces;
  word-break: break-word;

  & span {
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & svg {
    min-width: 26px;
    height: 16px;
    cursor: pointer;
  }
`;

export const EmptyListPlaceHolder = styled.span`
  padding: 32px;
  border-radius: 8px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  text-align: center;
  background: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primaryIcons};
`;

export const AddGCMButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.white};

  & .plus-icon {
    height: 20px;
    font-size: 20px;
    margin-right: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & button {
    margin-left: 10px;
  }
`;
