import React, { useState } from 'react';
import { Form, Input, Typography } from 'antd';
import PacService from 'lib/api/pacApi';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';
import { showErrorResponseNotification } from 'lib/helpers';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';

import { useAuth0 } from '@auth0/auth0-react';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  TopButtons,
  StyledText,
  FormWrapper,
  FlexWrapper,
} from './styled';

const { Title } = Typography;

const Content = styled.div`
  flex: 1;
  padding: 60px 100px;
  background-color: #fff;
  position: relative;
`;

const StyledForm = styled(Form)`
  max-width: 500px;
  margin-top: 40px;
`;

const InfoText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;

  & a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const CreateConfigurationStep2 = () => {
  const [isDataLoading, setLoading] = useState<boolean>(false);
  const { logout } = useAuth0();

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      OnboardingStorageService.setStep2Data(values);
      const step1Data = OnboardingStorageService.getStep1Data();

      const response = await PacService.createPacLite({
        ...step1Data,
        formDataCollectorInfo: {
          consentSelector: values.consentSelector || '',
          formSelector: values.formSelector || '',
          userIdSelector: values.userIdSelector || '',
        },
      });
      OnboardingStorageService.setOnboardingResults(response.data);
      return navigate('/onboarding/step-3');
    } catch (error) {
      return showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBack = () => navigate('/onboarding/step-1');
  const onCancel = async () => {
    OnboardingStorageService.setStep1Data({
      title: '',
      description: '',
      widgetTitle: '',
      widgetDescription: '',
      logoUrl: undefined,
      doubleOptIn: true,
    });
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout?onboarding=true`,
      },
    });
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Setup your Preference Center</StyledText>
          <Title level={2}>Let&apos;s create a configuration</Title>
          <span>
            A configuration represents a complete setup of your Preference Center including the
            widget that allows your users to manage their consent decisions. This Preference Center
            is available as a stand-alone page and accessible anytime for end users via
            authentication with email flow.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <Spin />
      ) : (
        <Content style={{ padding: '40px', background: '#fff', overflow: 'scroll' }}>
          <Title level={2}>Create Configuration</Title>
          <FlexWrapper>
            <div>
              <h3>Data Capture</h3>
              <p>Integrate data capture script into your existing web form.</p>
            </div>
            <FormWrapper>
              <InfoText>
                To generate data capture script, please enter some details about the form you want
                to use to capture user email and consent decision.
                <br />
                <br />
                To get this data, you will need to check the HTML code of your page and look for
                values in
                <strong> &quot;id&quot; </strong>parameters of the fields that correspond with the
                data needed.
                <br />
                <br />
                For more information on how to get these parameters - please refer to our{' '}
                <a href="https://docs.usercentrics.com/#/preference-manager">documentation</a>.
              </InfoText>
              <StyledForm form={form} layout="vertical" onFinish={onFinish}>
                <TopButtons>
                  <Button type="default" onClick={onClickBack}>
                    Back
                  </Button>
                  <Button type="default" onClick={onCancel}>
                    Cancel and Logout
                  </Button>
                  <Button type="primary" htmlType="submit" data-cy="button-finishSetup">
                    Next Step
                  </Button>
                </TopButtons>
                <Form.Item
                  label="ID of the form script to be integrated into"
                  name="formSelector"
                  data-cy="input-formScript"
                >
                  <Input placeholder="Field ID" />
                </Form.Item>

                <Form.Item
                  label="ID of the field that contains Email"
                  name="userIdSelector"
                  data-cy="input-emailField"
                >
                  <Input placeholder="Field ID" />
                </Form.Item>

                <Form.Item
                  label="ID of the field that contains consent decision"
                  name="consentSelector"
                  data-cy="input-consentField"
                >
                  <Input placeholder="Field ID" />
                </Form.Item>
              </StyledForm>
            </FormWrapper>
          </FlexWrapper>
        </Content>
      )}
    </Wrapper>
  );
};

export default CreateConfigurationStep2;
