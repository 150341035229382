import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  max-width: 740px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const DescriptionSection = styled.div`
  padding: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  background: ${(props) => props.theme.colors.lightBackgroundBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primaryIcons};
`;

export const DescriptionTitle = styled.p`
  margin: 0;
  flex: 1;
  & a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const IntegrationWidgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const IntegrationWidget = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 16px;
  width: calc(50% - 10px);
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  background: ${(props) => props.theme.colors.white};
  &.comingSoon {
    background: ${(props) => props.theme.colors.darkGray};
    border-color: transparent;
    & span {
      display: flex;
      padding: 4px 6px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 4px;
      background: ${(props) => props.theme.colors.darkerGray};
      color: ${(props) => props.theme.colors.lightDark};
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }
`;

export const IntegrationIcon = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  padding: 6.4px;
  justify-content: center;
  align-items: center;
  gap: 6.4px;
  border-radius: 4px;
  & img {
    width: 64px;
  }
`;

export const IntegrationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  & h3 {
    color: ${(props) => props.theme.colors.primaryText};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin: 0;
  }

  & a {
    font-weight: 500;
    font-style: normal;
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
  }
`;

export const AddedTag = styled.span`
  background-color: ${(props) => props.theme.colors.green};
  border-radius: 3px;
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  padding: 0 5px;

  & img {
    width: 14px;
    height: 14px;
  }
`;
