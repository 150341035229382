import React from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

import { Tooltip as TooltipIcon } from 'components/Icons';

import { overlayInnerStyle } from './Tooltip';

const StyledItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 8px;

  & .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }

  & .ant-form-item-control-input {
    min-height: auto;
  }

  & .ant-row {
    display: flex;
    flex-direction: column;
  }

  & .ant-form-item-label {
    text-align: left;
    margin-bottom: 4px;
    font-weight: 600;
  }

  & div:not(.ant-switch-handle) {
    width: 100%;
  }

  & .ant-form-item-explain {
    font-size: 12px;
    color: ${(props) => props.theme.colors.red};
    margin-top: 4px;
  }

  & .ant-form-item-required {
    &:after {
      display: inline-block !important;
      content: '*' !important;
      margin-right: 0;
    }

    &:before {
      content: '' !important;
    }
  }

  & .ant-form-item-tooltip {
    position: absolute;
    left: 100%;
    margin-left: 0 !important;

    & svg path {
      fill: ${(props) => props.theme.colors.gray};
    }
  }
`;

// eslint-disable-next-line
// This is a generic element that can be used in multiple ways, it's impossible to predict what properties
// or even elements are gonna be used.
const Item = ({ children, tooltip, ...props }: any) => (
  <StyledItem
    {...props}
    tooltip={
      tooltip ? { icon: <TooltipIcon />, placement: 'right', overlayInnerStyle, ...tooltip } : null
    }
  >
    {children}
  </StyledItem>
);

export default Item;
