import React, { useState } from 'react';

import Usercentrics from 'assets/usercentricsBlueBg.svg';

import { useMessage } from 'lib/hooks';
import { IWidget } from 'lib/interfaces/widget';
import UsercentricsCmpIntegrationApiService from 'lib/api/usercentricsCmpIntegration';
import Button from 'components/Button/Button';
import DeleteConfirmation from 'components/Modal/DeleteConfirmation';
import SidebarButtons from 'pages/PAC/SidebarButtons';

import { TrashCan as TrashCanIcon, Edit as EditIcon } from 'components/Icons';

import { ICmpIntegration } from 'lib/interfaces/cmpIntegration';
import { useParams } from 'react-router-dom';
import { Pac, PacType } from 'lib/api/pacApi';
import {
  AddWebhookButton,
  EmptyListPlaceHolder,
  WebhookBox,
  WebhookDescription,
  WebhookTitle,
  ButtonsWrapper,
} from './styled';
import { DescriptionSection, DescriptionTitle, PageContainer } from '../styled';
import CreateUsercentricsCMPModal from '../../../../components/Modal/CreateUsercentricsCMP';
import { TextWithTooltip } from '..';

interface IProps {
  widgets: Array<IWidget>;
  cmpIntegrationList: Array<ICmpIntegration>;
  fetchCmpIntegrations: () => void;
  pacDetails?: Pac;
}

const UsercentricsCmpIntegration = ({
  widgets,
  cmpIntegrationList,
  fetchCmpIntegrations,
  pacDetails,
}: IProps) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] = useState<ICmpIntegration | null>(null);
  const message = useMessage();
  const params: { pacId?: string } = useParams();

  return (
    <PageContainer data-cy="usercentrics-cmp-list-page">
      <DescriptionSection>
        <img src={Usercentrics} style={{ width: 64 }} alt="Usercentrics CMP" />

        <DescriptionTitle style={{ width: '50%' }}>
          <span
            style={{
              marginTop: '0',
              marginBottom: '0.5em',
              color: 'rgba(0,0,0,.85)',
              fontWeight: '500',
              display: 'block',
              fontSize: '1.17em',
            }}
          >
            Usercentrics CMP
          </span>
          <span>
            Using both UC Preference Manager and Consent Management products enables you to set up a
            Cross-device consent sharing feature, that allows to bind end user’s consent session
            with their User ID populated in Preference Manager tool. <br />
            For more details please refer to our documentation.
          </span>
          <br />
          <br />
          <a
            href="https://docs.usercentrics.com/#/preference-manager-integrations"
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
        </DescriptionTitle>
        <AddWebhookButton
          type="button"
          onClick={() => setCreateModalOpen(true)}
          disabled={pacDetails?.type === PacType.LITE}
          data-cy="integration-cmp-create-new-button"
        >
          <span className="plus-icon">+</span>{' '}
          <TextWithTooltip text="Connect" pacType={pacDetails?.type || PacType.FULL} />
        </AddWebhookButton>
      </DescriptionSection>
      {cmpIntegrationList.length === 0 ? (
        <EmptyListPlaceHolder>No configurations connected</EmptyListPlaceHolder>
      ) : (
        cmpIntegrationList.map((integration) => (
          <WebhookBox key={integration.uuid}>
            <div>
              <WebhookTitle>{integration.title}</WebhookTitle>
              <WebhookDescription>
                CMP Configuration ID: {integration.settingsId};{' '}
                {/* <TwoSidedArrowIcon width="26px" />{' '} */}
                <span>Consent required: {integration.consentRequired ? 'Yes' : 'No'}</span>
              </WebhookDescription>
            </div>
            <ButtonsWrapper>
              <Button
                onClick={() => {
                  setEditModalOpen(true);
                  setSelectedIntegration(integration);
                }}
                htmlType="button"
                type="border"
                data-cy="edit-webhook-button"
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteConfirmationModal(true);
                  setSelectedIntegration(integration);
                }}
                htmlType="button"
                type="border"
                data-cy="delete-webhook-button"
              >
                <TrashCanIcon />
              </Button>
            </ButtonsWrapper>
          </WebhookBox>
        ))
      )}

      {isCreateModalOpen && (
        <CreateUsercentricsCMPModal
          pacUuid={params.pacId as string}
          onClose={async () => {
            await fetchCmpIntegrations();
            setCreateModalOpen(false);
          }}
          widgetsList={widgets}
        />
      )}

      {showDeleteConfirmationModal && (
        <DeleteConfirmation
          onDelete={async () => {
            try {
              await UsercentricsCmpIntegrationApiService.deleteById(
                selectedIntegration?.uuid as string,
              );
              await fetchCmpIntegrations();
              setSelectedIntegration(null);
              setShowDeleteConfirmationModal(false);
            } catch (error) {
              message.error('something went wrong');
            }
          }}
          onClose={() => setShowDeleteConfirmationModal(false)}
          title="Delete CMP Integration?"
          text="Are you sure you want to delete this Integration?"
          primaryButtonLabel="Delete"
          primaryButtonDanger
          hideFooterBorder={false}
          width={480}
          isDefaultFooter={false}
        />
      )}
      {isEditModalOpen && (
        <CreateUsercentricsCMPModal
          pacUuid={params.pacId as string}
          onClose={async () => {
            await fetchCmpIntegrations();
            setCreateModalOpen(false);
          }}
          widgetsList={widgets}
          selectedIntegration={selectedIntegration as ICmpIntegration}
        />
      )}
      <SidebarButtons
        setHasUnsavedChanges={() => true}
        onSave={() => true}
        isSaveDisabled
        isPacDeactivated
      />
    </PageContainer>
  );
};

export default UsercentricsCmpIntegration;
