import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import CreateWebhookModal from 'components/Modal/CreateWebhook';
import CreateUsercentricsCMPModal from 'components/Modal/CreateUsercentricsCMP';
import CreateGCMIntegration from 'components/Modal/CreateGCMIntegration';

import Button from 'components/Button/Button';
import { ArrowRight } from 'components/Icons';
import { IWidget } from 'lib/interfaces/widget';
import { ICmpIntegration } from 'lib/interfaces/cmpIntegration';
import { IWebhook } from 'lib/interfaces/webhooks';
import { IGCMConfig } from 'lib/interfaces/googleCustomerMatch';

import Integration from 'assets/IntegrationHeader.png';
import Webhooks from 'assets/webhooks.svg';
import SalesForcePardot from 'assets/salesforcePardot.svg';
import SalesForce from 'assets/salesforce.svg';
import UsercentricsBlueBG from 'assets/usercentricsBlueBg.svg';
import Hubspot from 'assets/hubspot.svg';
import Braze from 'assets/braze.svg';
import Microsoft from 'assets/microsoft.svg';
import Added from 'assets/accept.svg';
import GCM from 'assets/gcm.svg';

import { Pac, PacType } from 'lib/api/pacApi';
import { Tooltip } from 'antd';
import useUserData from 'lib/hooks/useUserData';
import {
  AddedTag,
  DescriptionSection,
  DescriptionTitle,
  IntegrationIcon,
  IntegrationInfo,
  IntegrationWidget,
  IntegrationWidgetContainer,
  PageContainer,
} from './styled';

import SidebarButtons from '../SidebarButtons';

interface IProps {
  webhooksList: Array<IWebhook>;
  cmpIntegrationList: Array<ICmpIntegration>;
  gcmIntegrationsList: Array<IGCMConfig>;
  fetchWebhooks: () => void;
  fetchCmpIntegrations: () => void;
  fetchGcmIntegrations: () => void;
  widgets: Array<IWidget>;
  pacDetails?: Pac;
}

export const TextWithTooltip = ({ text, pacType }: { text: string; pacType: PacType }) => {
  return (
    <>
      {pacType === PacType.LITE && (
        <Tooltip
          title="This product package does not support this function. Please contact our support team to upgrade to the Preference Manager Full tier"
          placement="top"
        >
          {text}
        </Tooltip>
      )}
      {pacType === PacType.FULL && text}
    </>
  );
};

const IntegrationTab = ({
  webhooksList,
  fetchWebhooks,
  widgets,
  cmpIntegrationList,
  fetchCmpIntegrations,
  gcmIntegrationsList,
  fetchGcmIntegrations,
  pacDetails,
}: IProps) => {
  const [isCreateWebhookModalOpen, setCreateWebhookModalOpen] = useState<boolean>(false);
  const [isCreateUsercentricsCmpModalOpen, setCreateUsercentricsCmpModalOpen] =
    useState<boolean>(false);
  const [isCreateGCMModalOpen, setCreateGCMModalOpen] = useState<boolean>(false);
  const params: { pacId?: string } = useParams();
  const { isSuperAdmin } = useUserData();

  const hasAnyIntegrations =
    webhooksList.length > 0 || cmpIntegrationList.length > 0 || gcmIntegrationsList.length > 0;

  return (
    <PageContainer>
      <DescriptionSection>
        <DescriptionTitle>
          Integrate Usercentrics Preference Manager with third-party platforms to enable a direct
          transfer of user preferences and permissions. Stay compliant with business processes while
          optimizing your marketing campaigns.
        </DescriptionTitle>
        <img style={{ width: 320 }} src={Integration} alt="Integrations" />
      </DescriptionSection>
      {hasAnyIntegrations && (
        <>
          <DescriptionTitle style={{ width: '100%' }}>Your integrations</DescriptionTitle>
          <IntegrationWidgetContainer>
            {webhooksList.length > 0 && (
              <IntegrationWidget data-cy="integration-webhooks">
                <IntegrationIcon>
                  <img src={Webhooks} alt="Webhooks" />
                </IntegrationIcon>
                <IntegrationInfo>
                  <h3>Webhooks</h3>
                  <AddedTag>
                    <img src={Added} alt="img" />
                    Added
                  </AddedTag>
                </IntegrationInfo>
                <NavLink
                  to={`/configurations/${params.pacId}/integrations/webhooks`}
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  data-cy="link-to-webhooks-page"
                >
                  <ArrowRight />
                </NavLink>
              </IntegrationWidget>
            )}
            {cmpIntegrationList.length > 0 && (
              <IntegrationWidget data-cy="integration-cmp-usercentrics">
                <IntegrationIcon>
                  <img src={UsercentricsBlueBG} alt="Usercentrics CMP" />
                </IntegrationIcon>
                <IntegrationInfo>
                  <h3>Usercentrics CMP</h3>
                  <AddedTag>
                    <img src={Added} alt="img" />
                    Added
                  </AddedTag>
                </IntegrationInfo>
                <NavLink
                  to={`/configurations/${params.pacId}/integrations/cmp-usercentrics`}
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  data-cy="link-to-webhooks-page"
                >
                  <ArrowRight />
                </NavLink>
              </IntegrationWidget>
            )}
            {gcmIntegrationsList.length > 0 && (
              <IntegrationWidget data-cy="integration-gcm">
                <IntegrationIcon>
                  <img src={GCM} alt="Google Customer Match" />
                </IntegrationIcon>
                <IntegrationInfo>
                  <h3>Google Customer Match</h3>
                  <AddedTag>
                    <img src={Added} alt="img" />
                    Added
                  </AddedTag>
                </IntegrationInfo>
                <NavLink
                  to={`/configurations/${params.pacId}/integrations/gcm`}
                  style={{ marginLeft: 'auto', cursor: 'pointer' }}
                  data-cy="link-to-webhooks-page"
                >
                  <ArrowRight />
                </NavLink>
              </IntegrationWidget>
            )}
          </IntegrationWidgetContainer>
        </>
      )}

      <DescriptionTitle>All integrations</DescriptionTitle>
      <IntegrationWidgetContainer>
        {webhooksList.length === 0 && (
          <IntegrationWidget data-cy="integration-webhooks">
            <IntegrationIcon>
              <img src={Webhooks} alt="Webhooks" />
            </IntegrationIcon>
            <IntegrationInfo>
              <h3>Webhooks</h3>
              <Button
                data-cy="integration-webhooks-create-new-button"
                disabled={pacDetails?.type === PacType.LITE}
                type="link"
                onClick={() => setCreateWebhookModalOpen(true)}
                style={{ padding: 0 }}
              >
                <TextWithTooltip
                  text="Setup Integration"
                  pacType={pacDetails?.type || PacType.FULL}
                />
              </Button>
            </IntegrationInfo>
          </IntegrationWidget>
        )}
        <IntegrationWidget data-cy="integration-pardot">
          <IntegrationIcon>
            <img src={SalesForcePardot} alt="SalesForce" />
          </IntegrationIcon>
          <IntegrationInfo>
            <h3>Salesforce Pardot</h3>
            <Button
              type="link"
              style={{ padding: 0 }}
              disabled={pacDetails?.type === PacType.LITE}
              data-cy="integration-pardot-create-new-button"
              onClick={() => {
                window.open(
                  'https://usercentricssupport.zendesk.com/hc/en-us/requests/new?ticket_form_id=10659486950940',
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              <TextWithTooltip
                text="Request Integration"
                pacType={pacDetails?.type || PacType.FULL}
              />
            </Button>
          </IntegrationInfo>
        </IntegrationWidget>
        {gcmIntegrationsList.length === 0 && (
          <IntegrationWidget data-cy="integration-gcm">
            <IntegrationIcon>
              <img src={GCM} alt="GoogleCustomerMatch" />
            </IntegrationIcon>
            <IntegrationInfo>
              <h3>Google Customer Match</h3>
              <Button
                type="link"
                style={{ padding: 0 }}
                data-cy="integration-gcm-create-new-button"
                disabled={!isSuperAdmin}
                onClick={() => {
                  setCreateGCMModalOpen(true);
                }}
              >
                <TextWithTooltip
                  text="Setup Integration"
                  pacType={pacDetails?.type || PacType.FULL}
                />
              </Button>
            </IntegrationInfo>
          </IntegrationWidget>
        )}
        {cmpIntegrationList.length === 0 && (
          <IntegrationWidget data-cy="integration-uc-cmp">
            <IntegrationIcon>
              <img src={UsercentricsBlueBG} alt="Usercentrics CMP" />
            </IntegrationIcon>
            <IntegrationInfo>
              <h3>Usercentrics CMP</h3>
              <Button
                data-cy="integration-cmp-create-new-button"
                type="link"
                onClick={() => setCreateUsercentricsCmpModalOpen(true)}
                disabled={pacDetails?.type === PacType.LITE}
                style={{ paddingLeft: 0 }}
              >
                <TextWithTooltip
                  text="Setup Integration"
                  pacType={pacDetails?.type || PacType.FULL}
                />
              </Button>
            </IntegrationInfo>
          </IntegrationWidget>
        )}
        <IntegrationWidget className="comingSoon">
          <IntegrationIcon>
            <img src={Hubspot} alt="SalesForce" />
          </IntegrationIcon>
          <IntegrationInfo>
            <h3>Hubspot</h3>
            <span>Coming soon</span>
          </IntegrationInfo>
        </IntegrationWidget>
        <IntegrationWidget className="comingSoon">
          <IntegrationIcon>
            <img src={Microsoft} alt="SalesForce" />
          </IntegrationIcon>
          <IntegrationInfo>
            <h3>Microsoft Dynamics 365</h3>
            <span>Coming soon</span>
          </IntegrationInfo>
        </IntegrationWidget>
        <IntegrationWidget className="comingSoon">
          <IntegrationIcon>
            <img src={SalesForce} alt="SalesForce" />
          </IntegrationIcon>
          <IntegrationInfo>
            <h3>Salesforce</h3>
            <span>Coming soon</span>
          </IntegrationInfo>
        </IntegrationWidget>
        <IntegrationWidget className="comingSoon">
          <IntegrationIcon>
            <img src={Braze} alt="SalesForce" />
          </IntegrationIcon>
          <IntegrationInfo>
            <h3>Braze</h3>
            <span>Coming soon</span>
          </IntegrationInfo>
        </IntegrationWidget>
      </IntegrationWidgetContainer>
      <SidebarButtons
        setHasUnsavedChanges={() => true}
        onSave={() => true}
        isSaveDisabled
        isPacDeactivated
      />
      {isCreateUsercentricsCmpModalOpen && (
        <CreateUsercentricsCMPModal
          pacUuid={params.pacId as string}
          onClose={async () => {
            await fetchCmpIntegrations();
            setCreateUsercentricsCmpModalOpen(false);
          }}
          widgetsList={widgets}
        />
      )}
      {isCreateWebhookModalOpen && (
        <CreateWebhookModal
          onClose={() => {
            setCreateWebhookModalOpen(false);
          }}
          fetchWebhooks={() => fetchWebhooks()}
          widgetsList={widgets}
        />
      )}
      {isCreateGCMModalOpen && (
        <CreateGCMIntegration
          pacUuid={params.pacId as string}
          onClose={async () => {
            await fetchGcmIntegrations();
            setCreateGCMModalOpen(false);
          }}
          widgetsList={widgets}
        />
      )}
    </PageContainer>
  );
};

export default IntegrationTab;
