import React from 'react';
import { Tooltip } from 'antd';
import CodeSnippet from '..';

const AccessViaEmail = ({ pacId }: { pacId: string }) => (
  <CodeSnippet
    copyContent={`https://app-preference.preference-management.usercentrics.eu/login/${pacId}?lng=[LANG_CODE]`}
  >
    https://app-preference.preference-management.usercentrics.eu/login/
    <strong>{pacId}</strong>
    ?lng=
    <Tooltip
      title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
      placement="right"
    >
      <strong>[LANG_CODE]</strong>
    </Tooltip>
  </CodeSnippet>
);

export default AccessViaEmail;
