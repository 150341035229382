import React from 'react';
import { Description, Title } from 'shared-components/ModalsContent';
import { Item, Input } from 'components/Form';
import { Form, FormInstance, Select } from 'antd';
import { IWidget } from 'lib/interfaces/widget';

import { Label, SelectsWrapper } from './styled';

interface IProps {
  form: FormInstance<{ type: string }>;
  widgetsList: Array<IWidget>;
  setSelectedWidget: (value: string) => void;
  setSelectedTopic: (value: string) => void;
  setSelectedOption: (value: string) => void;
  selectedWidget: string;
  selectedTopic: string;
}

export default ({
  form,
  widgetsList,
  setSelectedWidget,
  setSelectedTopic,
  setSelectedOption,
  selectedWidget,
  selectedTopic,
}: IProps) => (
  <>
    <Title>Connect to Google Customer Match</Title>
    <Description>
      Here you can setup a Data Connector to Google Customer Match product, that will allow to build
      the customer segment lists with user emails obtained via Preference Manager based on the
      consent provided.
    </Description>
    <Form form={form} layout="vertical" style={{ padding: '16px' }}>
      <Item
        label="Title"
        name="title"
        required
        rules={[{ required: true, message: 'Please enter a title for the integration.' }]}
      >
        <Input data-cy="gcm-title" placeholder="Integration Title" />
      </Item>
      <Label>Select consent source:*</Label>
      <SelectsWrapper>
        <Form.Item
          name="widget"
          rules={[{ required: true, message: 'Please select a consent source.' }]}
        >
          <Select
            placeholder="Select Widget"
            onChange={(value) => {
              setSelectedWidget(value);
              setSelectedTopic('');
              setSelectedOption('');
              form.setFieldValue('topic', '');
              form.setFieldValue('option', '');
            }}
          >
            {widgetsList.map((widget) => (
              <Select.Option key={widget.uuid} value={widget.uuid}>
                {widget.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="topic"
          rules={[{ required: true, message: 'Please select a consent source.' }]}
        >
          <Select
            placeholder="Select Topic"
            onChange={(value) => {
              setSelectedTopic(value);
              setSelectedOption('');
              form.setFieldValue('option', '');
            }}
          >
            {widgetsList
              .find((widget) => widget.uuid === selectedWidget)
              ?.topics.map((topic) => (
                <Select.Option key={topic.uuid} value={topic.uuid}>
                  {topic.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="option"
          rules={[{ required: true, message: 'Please select a consent source.' }]}
        >
          <Select
            placeholder="Select Consent Source"
            onChange={(value) => {
              setSelectedOption(value);
            }}
          >
            {widgetsList
              .find((widget) => widget.uuid === selectedWidget)
              ?.topics.find((topic) => topic.uuid === selectedTopic)
              ?.options.map((option) => (
                <Select.Option key={option.uuid} value={option.uuid}>
                  {option.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </SelectsWrapper>
    </Form>
  </>
);
