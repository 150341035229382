import styled from 'styled-components';

export const Label = styled.span`
  margin: 0;
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: bold;
  height: 32px;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & .ant-form-item {
    width: 30%;
  }
`;
