import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import { Layout, message, Typography, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';

import { Item, Input } from 'components/Form';
import Button from 'components/Button/Button';
import { Google as GoogleIcon } from 'components/Icons';
import Spin from 'components/Spin';

import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import GCMIntegrationApiService from 'lib/api/googleCustomerMatchIntegrationApi';
import { showErrorResponseNotification } from 'lib/helpers';
import { numericTenSymbols } from 'lib/regex/validations';

import PacService, { Pac } from 'lib/api/pacApi';

import {
  StyledLogo,
  Wrapper,
  Sidebar,
  StyledText,
  FlexWrapper,
  FormWrapper,
  TopButtons,
  InfoText,
} from './styled';

const { Content } = Layout;
const { Title } = Typography;

const Page = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [isDataLoading, setLoading] = useState<boolean>(false);

  const [googleAccount, setGoogleAccount] = useState<string | null>(null);
  const [googleToken, setGoogleToken] = useState<string | null>(null);
  const [adsAccount, setAdsAccount] = useState('');
  const [customerListId, setCustomerListId] = useState('');

  const result = OnboardingStorageService.getOnboardingResults();

  const [pacData, setPacData] = useState<Pac>({} as Pac);

  const getPacInfo = async (id: string) => {
    setLoading(true);
    try {
      const response: AxiosResponse<Pac> = await PacService.getById(id);
      setPacData(response.data);
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (result.pacUuid === '') navigate('/onboarding/step-1');
    getPacInfo(result.pacUuid);
  }, []);

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/adwords',
    onSuccess: async (response) => {
      if (
        response.scope.includes('email') &&
        response.scope.includes('https://www.googleapis.com/auth/adwords')
      ) {
        try {
          const credentialsResponse = await GCMIntegrationApiService.getCredentials(response.code);

          setGoogleAccount(credentialsResponse.data.email);
          setGoogleToken(credentialsResponse.data.refreshToken);
          message.success('Google account connected successfully!');
        } catch (error) {
          showErrorResponseNotification(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        message.error('Requested scopes are not granted');
      }
    },
    onError: () => {
      setLoading(false);

      message.error('Google login failed. Please try again.');
    },
  });

  const onCancel = async () => {
    OnboardingStorageService.setStep1Data({
      title: '',
      description: '',
      widgetTitle: '',
      widgetDescription: '',
      logoUrl: undefined,
      doubleOptIn: true,
    });
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout?onboarding=true`,
      },
    });
  };

  const onFinish = async () => {
    if (googleAccount) {
      if (
        !numericTenSymbols.pattern.test(adsAccount) ||
        !numericTenSymbols.pattern.test(customerListId)
      ) {
        message.error('Customer ID or Customer List ID are empty');
        return null;
      }
      setLoading(true);

      try {
        await GCMIntegrationApiService.createConfig({
          pacUuid: result.pacUuid,
          title: 'Connector to Google Customer Match',
          consentSignalsSource: {
            widgetUuid: pacData.widgets[0].uuid,
            topicUuid: pacData.widgets[0].topics[0].uuid,
            optionUuidsFor: {
              userData: pacData.widgets[0].topics[0].options[0].uuid,
              personalization: pacData.widgets[0].topics[0].options[0].uuid,
            },
          },
          refreshToken: googleToken || '',
          email: googleAccount || '',
          customerId: adsAccount,
          customerListId,
        });
        message.success('You’ve successfully configured a connection with Google Customer Match!');
        return navigate('/onboarding/step-4');
      } catch (error) {
        showErrorResponseNotification(error);
        return null;
      } finally {
        setLoading(false);
      }
    } else {
      return navigate('/onboarding/step-4');
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Setup your Preference Center</StyledText>
          <Title level={2}>Let&apos;s create a configuration</Title>
          <span>
            A configuration represents a complete setup of your Preference Center including the
            widget that allows your users to manage their consent decisions. This Preference Center
            is available as a stand-alone page and accessible anytime for end users via
            authentication with email flow.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <Spin />
      ) : (
        <Content style={{ padding: '40px', background: '#fff', overflow: 'scroll' }}>
          <div>
            <Title level={2}>Activate Your data</Title>
            <FlexWrapper>
              <div>
                <h3>Link your GoogleAds account</h3>
                <p style={{ maxWidth: '450px' }}>
                  Connect with your GoogleAds account and select Google Customer List the data to be
                  feed into
                </p>
              </div>
              <FormWrapper>
                <TopButtons>
                  <Button type="default" onClick={onCancel}>
                    Cancel and Logout
                  </Button>
                  <Button type="primary" onClick={onFinish}>
                    Next Step
                  </Button>
                </TopButtons>

                {googleAccount ? (
                  <div>
                    <div style={{ textAlign: 'center' }}>
                      <p style={{ fontWeight: 'bold' }}>You are logged in as {googleAccount}.</p>
                      <Button
                        type="white"
                        onClick={() => setGoogleAccount(null)}
                        style={{ margin: 'auto' }}
                      >
                        Change Account
                      </Button>
                    </div>
                    <p>
                      Please choose Google Ads Account and define Customer Segment list the data
                      will be saved to.
                    </p>
                    <Form layout="vertical" style={{ padding: '16px' }}>
                      <Item
                        label="Google Ads Account (CustomerID)"
                        required
                        name="customerId"
                        rules={[
                          { required: true, message: 'Please enter your Google Ads Customer ID.' },
                          numericTenSymbols,
                        ]}
                      >
                        <Input
                          placeholder="Enter Google Ads Customer ID"
                          value={adsAccount}
                          onChange={(e) => setAdsAccount(e.target.value)}
                        />
                      </Item>
                      <Item
                        label="Customer List ID"
                        name="customerListId"
                        required
                        rules={[
                          { required: true, message: 'Please enter your Customer List ID.' },
                          numericTenSymbols,
                        ]}
                      >
                        <Input
                          placeholder="Enter Customer List ID"
                          value={customerListId}
                          onChange={(e) => setCustomerListId(e.target.value)}
                        />
                      </Item>
                    </Form>
                  </div>
                ) : (
                  <div data-cy="google-login-block">
                    <InfoText>
                      Good job, you have successfully created your new Preference Center
                      configuration!
                      <br />
                      <br />
                      Now, in order to transmit consented data captured by your preference center
                      further to Google marketing platform, please connect your GoogleAds account
                      and specify which Customer List you want to use.
                    </InfoText>
                    <Button
                      type="white"
                      onClick={() => {
                        setLoading(true);
                        login();
                      }}
                      style={{ margin: 'auto', marginBottom: '10px' }}
                      data-cy="google-login-button"
                    >
                      <GoogleIcon /> Sign in with Google
                    </Button>
                    <InfoText>
                      , or you can configure this
                      <a href={`${window.location.origin}/onboarding/step-4`}>
                        {' '}
                        step later in Admin Interface.
                      </a>
                    </InfoText>
                  </div>
                )}
              </FormWrapper>
            </FlexWrapper>
          </div>
        </Content>
      )}
    </Wrapper>
  );
};

const Step3 = () => (
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <Page />
  </GoogleOAuthProvider>
);

export default Step3;
