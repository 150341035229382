import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledFooter = styled(Layout.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  padding: 0 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  & button {
    cursor: pointer;
    display: inline;
    background: none;
    border: none;
    outline: none;
  }

  & a.ant-typography {
    font-size: 12px;

    color: ${({ theme }) => theme.colors.primaryText} !important;
  }

  & a,
  & button {
    &:hover {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;
