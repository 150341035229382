export const noWhitespaceAtEndAndBeginning = {
  pattern: /^[^\s]+(\s+[^\s]+)*$/,
  message: 'No whitespace at the beginning and end',
};

export const isNotEmptyField = {
  pattern: /.*\S.*/,
  message: 'This field cannot be empty',
};

export const hexCode = {
  pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
  message: 'Enter valid hex code (e.g. #0045A5)',
};

export const isNotFloat = {
  pattern: /^[0-9]+$/,
  message: 'This field must be an integer',
};

export const numericTenSymbols = {
  pattern: /^\d{10}$/,
  message: 'Must be numeric and exactly 10 digits.',
};
