export enum Steps {
  DETAILS = 'details',
  TOPICS = 'topics',
}

export enum LoadingType {
  PACS = 'pacs',
  PREFERENCES = 'preferences',
}

export enum GcmStep {
  DETAILS = 1,
  GOOGLE_AUTH = 2,
  GOOGLE_ADS = 3,
}
