import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';
import { message, Spin } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloProvider } from '@apollo/client';
import MainLayout from 'components/Layout/MainLayout';
import NotAuthorizedLayout from 'components/Layout/NotAuthorizedLayout';
import { useAuthenticated } from 'lib/hooks';
import { Flex } from 'shared-components';

// Pages
import GenericNotFound from 'components/Errors/GenericErrors';
import { AppContext } from 'lib/constants/global';
import { PacType } from 'lib/api/pacApi';
import AuthErrorPage from './Login/AuthErrorPage';
import PAC from './PAC';
import PreferenceData from './PreferenceData';
import Configurations from './Configurations';
import AccountSettings from './AccountSettings';
import Analytics from './Analytics';
import Logout from './Logout';
// Onboarding routes
import Step1 from './Onboarding/OnboardingStep1';
import Step2 from './Onboarding/OnboardingStep2';
import Step3 from './Onboarding/OnboardingStep3';
import Step4 from './Onboarding/OnboardingStep4';

export interface IPageData {
  [key: string]: {
    [key: string]: string | undefined | boolean | PacType;
  };
}

const App = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [nextPageBlocked, setNextPageBlocked] = useState<string | null>(null);
  const { isLoading, client, stateAppPrivileges, errorState } = useAuthenticated();
  const isSuperAdmin = !!stateAppPrivileges && stateAppPrivileges?.pmp.length > 0;
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const [pageData, setPageData] = useState<IPageData>({});
  let { isAuthenticated } = useAuth0();

  if (process.env.REACT_APP_E2E_TESTS === '1') {
    isAuthenticated = true;
  }
  const setPacPageData = (
    id: string,
    title: string,
    deactivated: boolean,
    type: PacType,
    description?: string,
  ) => {
    setPageData({
      pac: {
        id,
        title,
        description,
        deactivated,
        type,
      },
    });
  };

  const onPageUnmount = () => {
    setPageData({});
  };

  message.config({
    duration: 5,
  });

  const [notify, contextHolder] = message.useMessage();

  useEffect(() => {
    async function checkRoute() {
      if (location.pathname === '/signup' || location.pathname === 'signup') {
        loginWithRedirect({
          authorizationParams: { screen_hint: 'signup' },
        });
      } else if (location.pathname === '/login' || location.pathname === 'login') {
        loginWithRedirect();
      }
    }
    checkRoute();
  }, [location, loginWithRedirect]);

  if (errorState) {
    return (
      <AppContext.Provider
        value={{
          message: notify,
          user: { isSuperAdmin },
        }}
      >
        <AuthErrorPage error={errorState?.error_description} />
      </AppContext.Provider>
    );
  }

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <ApolloProvider client={client}>
      <Routes>
        {!isAuthenticated && (
          <Route element={<NotAuthorizedLayout />}>
            <Route path="/logout" element={<Logout />} />
          </Route>
        )}
        <Route
          element={
            <AppContext.Provider
              value={{
                message: notify,
                user: { isSuperAdmin },
              }}
            >
              {contextHolder}
              <Outlet />
            </AppContext.Provider>
          }
        >
          <Route path="/403" element={<GenericNotFound status={403} />} />
          <Route path="/unknownError" element={<GenericNotFound status={100} />} />
          <Route path="/404" element={<GenericNotFound status={404} />} />
          <Route path="/500" element={<GenericNotFound status={500} />} />
          {isAuthenticated && (
            <>
              <Route path="/onboarding/step-1" element={<Step1 />} />
              <Route path="/onboarding/step-2" element={<Step2 />} />
              <Route path="/onboarding/step-3" element={<Step3 />} />
              <Route path="/onboarding/step-4" element={<Step4 />} />
              <Route
                element={
                  <MainLayout
                    hasUnsavedChanges={hasUnsavedChanges}
                    pageData={pageData}
                    setNextPageBlocked={setNextPageBlocked}
                  />
                }
              >
                <Route path="/configurations" element={<Configurations />} />
                <Route path="/configurations/:pacId">
                  <Route
                    path="/configurations/:pacId/widgets"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="Widgets"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/settings"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="General"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/appearance"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="Appearance"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/implementation"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="Implementation"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/integrations"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="Integrations"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/integrations/webhooks"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="Webhooks"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/integrations/cmp-usercentrics"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="CmpUsercentrics"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId/integrations/gcm"
                    element={
                      <PAC
                        onPageDataLoad={setPacPageData}
                        onPageUnmount={onPageUnmount}
                        selectedTab="GCM"
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        setNextPageBlocked={setNextPageBlocked}
                        nextPageBlocked={nextPageBlocked}
                      />
                    }
                  />
                  <Route
                    path="/configurations/:pacId"
                    element={<Navigate to="widgets" replace />}
                  />
                </Route>
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route path="/preference-data" element={<PreferenceData />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/" element={<Navigate to="configurations" replace />} />
                <Route path="/onboarding" element={<Navigate to="/onboarding/step-1" replace />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </ApolloProvider>
  );
};

export default App;
