import React from 'react';
import {
  User as UserPlaceholder,
  Help as HelpIcon,
  KnowledgeBase as KnowledgeBaseIcon,
  Documentation as DocumentationIcon,
  Headphones as HeadphonesIcon,
  Notifications as NotificationsIcon,
} from 'components/Icons';
import Logo from 'components/Logo';
import { MenuProps, Dropdown, Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useLogout } from 'lib/hooks';

import { Flex } from 'shared-components';

import {
  Header,
  UserWrapper,
  DateLabel,
  UserInfoWrapper,
  UserName,
  BreadCrumbContainer,
  DropdownItem,
  BeamerButton,
} from './styled';
import BreadCrumb from '../BreadCrumb';
import { IPageData } from '../../pages/App';

interface IHeaderComponent {
  hideInfo?: boolean;
  pageData?: IPageData;
}

const HeaderComponent = ({ hideInfo, pageData }: IHeaderComponent) => {
  const { user } = useAuth0();
  const logOut = useLogout(true);

  const userMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <UserInfoWrapper>
          <UserPlaceholder />
          <div>
            <UserName>{user?.name}</UserName>
            <p>{user?.email}</p>
            {user?.updated_at && (
              <DateLabel>
                Last login: {new Date(user?.updated_at).toLocaleString('en-GB')}
              </DateLabel>
            )}
          </div>
        </UserInfoWrapper>
      ),
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <DropdownItem key="logout" onClick={logOut}>
          Logout
        </DropdownItem>
      ),
    },
  ];

  const knowledgeBaseItems: MenuProps['items'] = [
    {
      key: '1',
      icon: <KnowledgeBaseIcon />,
      label: (
        <a
          href="https://support.usercentrics.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Knowledge Base
        </a>
      ),
    },
    {
      key: '2',
      icon: <DocumentationIcon />,
      label: (
        <a
          href="https://docs.usercentrics.com/#/preference-manager"
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
        </a>
      ),
    },
    {
      key: '3',
      icon: <HeadphonesIcon />,
      label: (
        <a
          href="https://usercentricssupport.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
      ),
    },
  ];

  return (
    <Header $hideInfo={hideInfo}>
      <Logo width={190} />
      <Flex justifyContent="start" style={{ width: '100%' }}>
        {!hideInfo && (
          <BreadCrumbContainer>
            <BreadCrumb pageData={pageData} />
          </BreadCrumbContainer>
        )}

        {!hideInfo && user && (
          <UserWrapper>
            {/* BEAMER implementation */}
            <BeamerButton type="button" id="beamerButton" className="beamerButton">
              <Tooltip placement="bottom" title="What's New">
                <NotificationsIcon />
              </Tooltip>
            </BeamerButton>
            <Dropdown
              menu={{ items: knowledgeBaseItems }}
              align={{ offset: [-10, 0] }}
              trigger={['click']}
            >
              <span className="ant-dropdown-link" style={{ paddingRight: '10px' }}>
                <Tooltip placement="bottom" title="Help">
                  <HelpIcon />
                </Tooltip>
              </span>
            </Dropdown>
            <Dropdown
              menu={{ items: userMenuItems }}
              placement="bottomRight"
              align={{ offset: [-10, 0] }}
            >
              <span className="ant-dropdown-link">
                <UserPlaceholder />
              </span>
            </Dropdown>
          </UserWrapper>
        )}
      </Flex>
    </Header>
  );
};

export default HeaderComponent;
