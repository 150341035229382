import React from 'react';
import { Tooltip } from 'antd';
import CodeSnippet from '..';

const EmbeddedWidget = () => (
  <CodeSnippet
    copyContent={`<div
class="embedded-preference-widget"
data-token="[JWT_TOKEN]"
data-widgetid="[WIDGET_ID]"
data-language="[LANG_CODE]">
</div>
<link
href="https://embeddings.preference-management.usercentrics.eu/latest/styles.css"
rel="stylesheet">
<script
src="https://embeddings.preference-management.usercentrics.eu/latest/bundle.js">
</script>`}
  >
    {'<div'}
    <br />
    &nbsp;&nbsp;class=&quot;embedded-preference-widget&quot;
    <br />
    &nbsp;&nbsp;data-token=&quot;
    <Tooltip
      title="In advance generated access token for the given UserID. To obtain a token, execute a direct call to the dedicated Public API end-point."
      placement="right"
    >
      <strong>[JWT_TOKEN]</strong>
    </Tooltip>
    &quot;
    <br />
    &nbsp;&nbsp;data-widgetid=&quot;
    <Tooltip
      title="Unique ID of the widget to be rendered, that can be found in the Widget management page."
      placement="right"
    >
      <strong>[WIDGET_ID]</strong>
    </Tooltip>
    &quot;
    <br />
    &nbsp;&nbsp;data-language=&quot;
    <Tooltip
      title="The language code of the localized version you want to use in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
      placement="right"
    >
      <strong>[LANG_CODE]</strong>
    </Tooltip>
    &quot;
    <br />
    {'>'}
    <br />
    {'</div>'}
    <br />
    {'<link'}
    <br />
    &nbsp;&nbsp;
    href=&quot;https://embeddings.preference-management.usercentrics.eu/latest/styles.css&quot;
    <br />
    &nbsp;&nbsp;{'rel="stylesheet">'}
    <br />
    {'<script '}
    <br />
    &nbsp;&nbsp;
    {'src="https://embeddings.preference-management.usercentrics.eu/latest/bundle.js"></script>'}
  </CodeSnippet>
);

export default EmbeddedWidget;
