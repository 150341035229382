import React, { useEffect, useState } from 'react';
import { IWidget } from 'lib/interfaces/widget';
import { Steps } from 'lib/enums/steps';
import { clickToCopy } from 'lib/helpers/clickToCopy';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import artBoardImage from 'assets/ArtBoard.svg';

import { StyledTooltip, overlayInnerStyle } from 'components/Form/Tooltip';
import { Tooltip } from 'antd';

import Button from 'components/Button/Button';
import EditWidgetModal from 'components/Modal/CreateWidget/EditWidget';
import CreateWidgetModal from 'components/Modal/CreateWidget';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import WidgetMenu from 'components/Menu/WidgetMenu';
import { InfoIcon } from 'lib/hooks/useInformNotification/styled';
import { DragIndicator as DragIndicatorIcon, Copy } from 'components/Icons';
import StyledSwitch from 'components/Switch/Switch';

import { Pac, PacType } from 'lib/api/pacApi';
import ViewWidgetStructure from 'components/Modal/ViewWidgetStructure';
import {
  Container,
  WidgetBox,
  WidgetTitle,
  WidgetID,
  WidgetOverviewContainer,
  AddWidgetButtonContainer,
  WarningText,
  DragWrapper,
  WidgetTitleContainer,
  WidgetSwitchContainer,
  WidgetMenuContainer,
} from './styled';
import SidebarButtons from '../SidebarButtons';
import useUserData from '../../../lib/hooks/useUserData';

const MAX_WIDGET_LIMIT = 10;
interface IProps {
  widgets: Array<IWidget> | [];
  fetchWidgets: () => void;
  exportReq: (id: string) => void;
  deleteWidgetReq: (uuid: string) => void;
  updateWidgetOrder: (widgets: IWidget[]) => void;
  hiddenWidgetChange: (widgetUuid: string, hiddenNewState: boolean) => void;
  editableLanguages: string[];
  languageList: { [key: string]: string };
  pacDetails?: Pac;
  isWidgetLoading: boolean;
}

const reorder = (list: Array<IWidget>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const WidgetsList = ({
  widgets,
  fetchWidgets,
  exportReq,
  deleteWidgetReq,
  updateWidgetOrder,
  hiddenWidgetChange,
  editableLanguages,
  languageList,
  pacDetails,
  isWidgetLoading,
}: IProps) => {
  const [widgetToEdit, setWidgetToEdit] = useState<IWidget | null>(null);
  const [editModalStep, setEditModalStep] = useState<Steps>(Steps.DETAILS);
  // TODO: move pacDetails to context
  const isPACLite = pacDetails?.type === PacType.LITE;
  const [createNewWidget, toggleCreateModal] = useState(false);
  const isWidgetLimit = widgets?.length === MAX_WIDGET_LIMIT || (isPACLite && widgets?.length >= 1);
  const [copied, setCopied] = useState<boolean>(false);
  const [widgetToViewStructure, setWidgetToViewStructure] = useState<IWidget | null>(null);
  const { isSuperAdmin } = useUserData();

  const onDragEnd = (result: DropResult) => {
    if (widgets && result.source.index !== result?.destination?.index) {
      const reorderedWidgets = reorder(
        widgets,
        result.source.index,
        result?.destination?.index || 0,
      );
      updateWidgetOrder(reorderedWidgets);
    }
  };

  const onHiddenChange = (widgetUuid: string, hiddenNewState: boolean) => {
    hiddenWidgetChange(widgetUuid, hiddenNewState);
  };

  useEffect(() => {
    if (widgetToEdit) {
      widgets?.forEach((widget) => {
        if (widget.uuid === widgetToEdit.uuid) {
          setWidgetToEdit(widget);
        }
      });
    }
  }, [widgets]);

  return (
    <Container>
      {widgets && widgets.length > 0 ? (
        <div>
          <WidgetOverviewContainer>
            <h2>Widget Overview</h2>
            <section data-cy="configuration-widget-total-count">
              {widgets.length} / {MAX_WIDGET_LIMIT}
            </section>
          </WidgetOverviewContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(providedDroppable) => (
                <div
                  style={{ minHeight: `${widgets.length * 110}px` }}
                  ref={providedDroppable.innerRef}
                  {...providedDroppable.droppableProps}
                >
                  {widgets.map((widget, index) => (
                    <div style={{ position: 'relative' }} key={`div-${widget.uuid}`}>
                      <Draggable
                        key={widget.uuid}
                        draggableId={widget.uuid}
                        index={index}
                        isDragDisabled={widgets.length === 1}
                      >
                        {(provided) => (
                          <DragWrapper
                            data-cy={`configuration-widget-${widget.uuid}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon />
                            <WidgetBox key={widget._id}>
                              <WidgetTitleContainer>
                                <WidgetTitle
                                  className="configuration-widget-title-label"
                                  data-cy={`configuration-widget-${widget.uuid}-title-label`}
                                >
                                  {widget.title}
                                </WidgetTitle>
                                <WidgetID data-cy={`configuration-widget-${widget.uuid}-id-label`}>
                                  Widget ID: {widget.uuid}
                                  <StyledTooltip
                                    title={copied ? 'Copied' : 'Copy to clipboard'}
                                    placement="right"
                                    overlayInnerStyle={overlayInnerStyle}
                                  >
                                    <Copy
                                      style={{ marginLeft: '10px' }}
                                      onClick={() => {
                                        clickToCopy(widget.uuid, setCopied);
                                      }}
                                      data-cy={`configuration-widget-${widget.uuid}-copy-icon`}
                                    />
                                  </StyledTooltip>
                                </WidgetID>
                              </WidgetTitleContainer>
                              <WidgetSwitchContainer>
                                <StyledTooltip
                                  title="Turn on and off widget display on the Preference Center page"
                                  placement="top"
                                  overlayInnerStyle={overlayInnerStyle}
                                  mouseLeaveDelay={0}
                                >
                                  {/* Info: this workaround needed to hide tooltip properly while loading state: https://github.com/ant-design/ant-design/issues/17357#issuecomment-650866269 */}
                                  <span
                                    style={{ cursor: isWidgetLoading ? 'not-allowed' : 'pointer' }}
                                  >
                                    <StyledSwitch
                                      loading={isWidgetLoading}
                                      style={{ pointerEvents: isWidgetLoading ? 'none' : 'auto' }}
                                      data-cy={`configuration-widget-${widget.uuid}-hidden-toggle`}
                                      checked={!widget?.hidden}
                                      onChange={() => onHiddenChange(widget.uuid, !widget?.hidden)}
                                    />
                                  </span>
                                </StyledTooltip>
                              </WidgetSwitchContainer>
                            </WidgetBox>
                          </DragWrapper>
                        )}
                      </Draggable>
                      <WidgetMenuContainer>
                        <WidgetMenu
                          key={`configuration-widget-${widget.uuid}-menu`}
                          dataCy={`configuration-widget-${widget.uuid}-menu`}
                          widgetUuid={widget.uuid}
                          onEditDetails={() => {
                            setEditModalStep(Steps.DETAILS);
                            setWidgetToEdit(widget);
                          }}
                          onEditTopics={() => {
                            setEditModalStep(Steps.TOPICS);
                            setWidgetToEdit(widget);
                          }}
                          onExport={() => exportReq(widget.uuid)}
                          onDelete={() => deleteWidgetReq(widget.uuid)}
                          onViewWidgetStructure={() => setWidgetToViewStructure(widget)}
                        />
                      </WidgetMenuContainer>
                    </div>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <AddWidgetButtonContainer>
            {pacDetails?.type === PacType.LITE ? (
              <Tooltip
                title="This product package does not support this function. Please contact our support team to upgrade to the Preference Manager Full tier"
                placement="top"
              >
                <Button
                  onClick={() => null}
                  style={{ opacity: 0.7 }}
                  type="primary"
                  data-cy="configuration-widget-button-add-widget"
                >
                  Add Widget
                </Button>
              </Tooltip>
            ) : (
              <Button
                onClick={() => toggleCreateModal(true)}
                disabled={(!!pacDetails?.deactivated && !isSuperAdmin) || isWidgetLimit}
                type="primary"
                data-cy="configuration-widget-button-add-widget"
              >
                Add Widget
              </Button>
            )}
            {isWidgetLimit && (
              <WarningText data-cy="configuration-widget-warning-label">
                <InfoIcon /> You’ve reached maximum amount of Widgets for this Configuration
              </WarningText>
            )}
          </AddWidgetButtonContainer>
        </div>
      ) : (
        <EmptyListPlaceholder
          title="No widgets created"
          image={artBoardImage}
          description="Your widgets will appear here. You can create up to ten widgets in a single configuration. Create the first one."
          button={{
            text: 'Create Widget',
            onClick: () => toggleCreateModal(true),
            dataId: 'addWidget',
          }}
        />
      )}

      <SidebarButtons
        setHasUnsavedChanges={() => true}
        onSave={() => true}
        isSaveDisabled
        isPacDeactivated
      />
      {createNewWidget && (
        <CreateWidgetModal
          isPacDeactivated={!!pacDetails?.deactivated}
          editableLanguages={editableLanguages}
          languageList={languageList}
          onClose={() => {
            fetchWidgets();
            toggleCreateModal(false);
          }}
          isPacLite={isPACLite}
        />
      )}

      {widgetToViewStructure && (
        <ViewWidgetStructure
          widget={widgetToViewStructure}
          pacDetails={pacDetails}
          onClose={() => setWidgetToViewStructure(null)}
        />
      )}

      {widgetToEdit && (
        <EditWidgetModal
          isPacDeactivated={!!pacDetails?.deactivated}
          editableLanguages={editableLanguages}
          languageList={languageList}
          fetchWidgets={fetchWidgets}
          widget={widgetToEdit}
          onClose={() => {
            setWidgetToEdit(null);
            fetchWidgets();
          }}
          modalStep={editModalStep}
          isPacLite={isPACLite}
        />
      )}
    </Container>
  );
};

export default WidgetsList;
