import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import GCM from 'assets/gcm.svg';

import { IWidget } from 'lib/interfaces/widget';
import { IGCMConfig } from 'lib/interfaces/googleCustomerMatch';

import Button from 'components/Button/Button';
import CreateGCMIntegration from 'components/Modal/CreateGCMIntegration';
import EditGCMModal from 'components/Modal/EditGCMIntegration';
import GCMIntegrationApiService from 'lib/api/googleCustomerMatchIntegrationApi';

import SidebarButtons from 'pages/PAC/SidebarButtons';

import { TrashCan as TrashCanIcon, Edit as EditIcon } from 'components/Icons';

import DeleteConfirmation from 'components/Modal/DeleteConfirmation';
import { useMessage } from 'lib/hooks';
import {
  AddGCMButton,
  EmptyListPlaceHolder,
  GCMBox,
  GCMDescription,
  GCMTitle,
  ButtonsWrapper,
} from './styled';
import { DescriptionSection, DescriptionTitle, PageContainer } from '../styled';

interface IProps {
  widgets: Array<IWidget>;
  gcmIntegrationsList: Array<IGCMConfig>;
  fetchGcmIntegrations: () => void;
}

const GoogleCustomerMatchIntegration = ({
  widgets,
  gcmIntegrationsList,
  fetchGcmIntegrations,
}: IProps) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [selectedGCMConfig, setSelectedGCMConfig] = useState<IGCMConfig>({} as IGCMConfig);
  const message = useMessage();
  const params: { pacId?: string } = useParams();

  return (
    <PageContainer data-cy="gcm-list-page">
      <DescriptionSection>
        <img src={GCM} alt="Google Customer Match" style={{ width: '64px' }} />

        <DescriptionTitle style={{ width: '50%' }}>
          <span
            style={{
              marginTop: '0',
              marginBottom: '0.5em',
              color: 'rgba(0,0,0,.85)',
              fontWeight: '500',
              display: 'block',
              fontSize: '1.17em',
            }}
          >
            Google Customer Match
          </span>
          <span>
            By setting up connection between UC Preference Manager and your Google Customer Match
            account, you can build your own customer segment lists based on the end-user consent
            decisions obtained as part of preference submissions. For more details please refer to
            our{' '}
            <a
              href="https://docs.usercentrics.com/#/preference-manager-integrations"
              target="_blank"
              rel="noreferrer"
            >
              Documentation
            </a>
          </span>
        </DescriptionTitle>
        <AddGCMButton
          type="button"
          onClick={() => setCreateModalOpen(true)}
          data-cy="integration-gcm-create-new-button"
        >
          <span className="plus-icon">+</span> Connect
        </AddGCMButton>
      </DescriptionSection>
      {gcmIntegrationsList.length === 0 ? (
        <EmptyListPlaceHolder>No Google Customer Match connected</EmptyListPlaceHolder>
      ) : (
        gcmIntegrationsList.map((gcm) => (
          <GCMBox key={gcm.title + gcm.pacUuid}>
            <div style={{ maxWidth: '80%' }}>
              <GCMTitle>{gcm.title}</GCMTitle>
              <GCMDescription>
                <span>Customer List: {gcm.customerListId}</span>
              </GCMDescription>
            </div>
            <ButtonsWrapper>
              <Button
                onClick={() => {
                  setSelectedGCMConfig(gcm);
                  setEditModalOpen(true);
                }}
                htmlType="button"
                type="border"
                data-cy="edit-gcm-button"
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteConfirmationModal(true);
                  setSelectedGCMConfig(gcm);
                }}
                htmlType="button"
                type="border"
                data-cy="delete-gcm-button"
              >
                <TrashCanIcon />
              </Button>
            </ButtonsWrapper>
          </GCMBox>
        ))
      )}
      {isEditModalOpen && (
        <EditGCMModal
          gcmConfig={selectedGCMConfig}
          onClose={async () => {
            await fetchGcmIntegrations();
            setCreateModalOpen(false);
          }}
          widgetsList={widgets}
        />
      )}

      {showDeleteConfirmationModal && (
        <DeleteConfirmation
          onDelete={async () => {
            try {
              await GCMIntegrationApiService.delete(selectedGCMConfig?.uuid as string);
              await fetchGcmIntegrations();
              setSelectedGCMConfig({} as IGCMConfig);
              setShowDeleteConfirmationModal(false);
            } catch (error) {
              message.error('Something went wrong');
            }
          }}
          onClose={() => setShowDeleteConfirmationModal(false)}
          title="Delete Google Customer Match connection?"
          text="Are you sure you want to delete this connection to Google Customer Match?"
          primaryButtonLabel="Delete"
          primaryButtonDanger
          hideFooterBorder={false}
          width={480}
          isDefaultFooter={false}
        />
      )}

      {isCreateModalOpen && (
        <CreateGCMIntegration
          pacUuid={params.pacId as string}
          onClose={async () => {
            await fetchGcmIntegrations();
            setCreateModalOpen(false);
          }}
          widgetsList={widgets}
        />
      )}
      <SidebarButtons
        setHasUnsavedChanges={() => true}
        onSave={() => true}
        isSaveDisabled
        isPacDeactivated
      />
    </PageContainer>
  );
};

export default GoogleCustomerMatchIntegration;
