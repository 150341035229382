import React from 'react';
import { Badge, Flex, Typography } from 'antd-v5';
// import { Link, Text } from 'components/Typography';

import { StyledFooter } from './styled';

const ContentFooter = () => {
  return (
    <StyledFooter>
      <Typography.Text>© Copyright {new Date().getFullYear()} Usercentrics GmbH</Typography.Text>
      <Flex align="center" gap={20}>
        <Badge status="default" />
        <Typography.Link
          href="https://usercentrics.com/privacy-policy-pmp/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </Typography.Link>
        <Badge status="default" />
        <Typography.Link
          href="https://usercentrics.com/legal-notice/"
          target="_blank"
          rel="noreferrer"
        >
          Legal Notice
        </Typography.Link>
        <Badge status="default" />
        <Typography.Link
          href="https://usercentricsgmbh.statuspage.io/"
          target="_blank"
          rel="noreferrer"
        >
          System Status
        </Typography.Link>
      </Flex>
    </StyledFooter>
  );
};

export default ContentFooter;
