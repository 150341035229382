import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from 'components/SideBar';
import Header from 'components/Header';
import ContentFooter from 'components/Footer';
import { Layout, Content, Container } from './styled';
import { IPageData } from '../../pages/App';

interface IMainLayoutProps {
  hideInfo?: boolean;
  pageData?: IPageData;
  hasUnsavedChanges: boolean;
  setNextPageBlocked: (nextPage: string) => void;
}

const MainLayout = ({
  hideInfo,
  pageData,
  hasUnsavedChanges,
  setNextPageBlocked,
}: IMainLayoutProps) => (
  <Layout>
    <Header hideInfo={hideInfo} pageData={pageData} />
    <Container>
      <SideBar hasUnsavedChanges={hasUnsavedChanges} setNextPageBlocked={setNextPageBlocked} />
      <Content>
        <Outlet />
        <ContentFooter />
      </Content>
    </Container>
  </Layout>
);

export default MainLayout;
