import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PacService, { Pac } from 'lib/api/pacApi';
import { Checkbox, Form, Input, Layout, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';
import { showErrorResponseNotification } from 'lib/helpers';
import privateKeysService from 'lib/api/privateKeysApi';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import { ApiKeyType } from 'lib/enums/apiKey';
import { IOnboardingStep1 } from 'lib/interfaces/onboarding';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';
import { StyledQuill } from 'pages/PAC/styled';

import { isNotEmptyField } from 'lib/regex/validations';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  TopButtons,
  StyledSwitch,
  StyledText,
  FlexWrapper,
  FormWrapper,
  ConsentCheck,
} from './styled';

const { Content } = Layout;
const { Title } = Typography;

const Step1 = () => {
  const [form] = Form.useForm();
  const { user, logout } = useAuth0();
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const navigate = useNavigate();

  const data = OnboardingStorageService.getStep1Data();

  const checkPac = async () => {
    try {
      const response = await PacService.get({
        search: user?.email,
      });
      const pacsThatCustomerOwns = response.data.results;
      const pacLite = pacsThatCustomerOwns.find((el: Pac) => el.type === 'lite');

      if (pacLite) {
        try {
          const apiKeys = await privateKeysService.get();
          OnboardingStorageService.setOnboardingResults({
            widgetUuid: pacLite?.widgetUuids[0],
            pacUuid: pacLite.uuid,
            publicApiKey:
              apiKeys.data.apiKeys?.find((k) => k.type === ApiKeyType.PUBLIC)?.key || '',
            optionUuid: pacLite?.widgets[0]?.topics[0]?.options[0].uuid,
          });
          navigate('/onboarding/step-4');
        } catch (error) {
          showErrorResponseNotification(error);
        }
      }
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkPac();
  }, []);

  const onFinish = (values: IOnboardingStep1) => {
    OnboardingStorageService.setStep1Data({
      ...values,
      logoUrl: values.logoUrl === '' ? undefined : values.logoUrl,
    });
    navigate('/onboarding/step-2');
  };

  const onCancel = async () => {
    OnboardingStorageService.setStep1Data({
      title: '',
      description: '',
      widgetTitle: '',
      widgetDescription: '',
      logoUrl: undefined,
      doubleOptIn: false,
    });
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout?onboarding=true`,
      },
    });
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Setup your Preference Center</StyledText>
          <Title level={2}>Let&apos;s create a configuration</Title>
          <span>
            A configuration represents a complete setup of your Preference Center including the
            widget that allows your users to manage their consent decisions. This Preference Center
            is available as a stand-alone page and accessible anytime for end users via
            authentication with email flow.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <Spin />
      ) : (
        <Content style={{ padding: '40px', background: '#fff', overflow: 'scroll' }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={
              data || {
                title: '',
                description: '',
                widgetTitle: '',
                widgetDescription: '',
                logoUrl: '',
                doubleOptIn: false,
              }
            }
          >
            <Title level={2}>Create Configuration</Title>
            <TopButtons>
              <Button type="default" onClick={onCancel}>
                Cancel and Logout
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                data-cy="button-nextStep"
                disabled={!termsAndConditions}
              >
                Next Step
              </Button>
            </TopButtons>
            <FlexWrapper>
              <div>
                <h3>Configuration Details</h3>
                <p>Add your configuration details here.</p>
              </div>
              <div>
                <FormWrapper>
                  <Form.Item
                    name="title"
                    label="Preference Center Title"
                    rules={[{ required: true, message: 'Please enter a title' }, isNotEmptyField]}
                    data-cy="input-preferenceCenterTitle"
                  >
                    <Input placeholder="Your Preference Center" />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Preference Center Description Text"
                    data-cy="input-preferenceCenterDescription"
                  >
                    <StyledQuill
                      modules={{
                        toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                      }}
                      formats={['bold', 'italic', 'underline', 'strike', 'link']}
                      placeholder="Welcome to our preference center page..."
                      theme="snow"
                      style={{ fontSize: '14px' }}
                      data-cy="onboarding-pac-description-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="widgetTitle"
                    label="Consent Section Title"
                    rules={[
                      { required: true, message: 'Please enter consent section title' },
                      isNotEmptyField,
                    ]}
                    data-cy="input-consentTitle"
                  >
                    <Input placeholder="Choose your privacy settings" />
                  </Form.Item>

                  <Form.Item
                    name="widgetDescription"
                    label="Consent Section Description Text"
                    data-cy="input-ConsentSectionCenterDescription"
                  >
                    <StyledQuill
                      modules={{
                        toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                      }}
                      formats={['bold', 'italic', 'underline', 'strike', 'link']}
                      placeholder="Here you can view and manage your consents settings"
                      theme="snow"
                      style={{ fontSize: '14px' }}
                      data-cy="onboarding-widget-description-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="logoUrl"
                    label="URL to Your Company Logo"
                    data-cy="input-companyLogo"
                    rules={[
                      {
                        type: 'url',
                        message: 'This field must be a valid url.',
                      },
                    ]}
                  >
                    <Input placeholder="https://..." />
                  </Form.Item>

                  <Tooltip
                    title="You can activate the double opt-in scenario for your data, requiring users to confirm their data submissions via a confirmation email. If not confirmed, pending data will be deleted after 24 hours and will not be processed in connected marketing platforms."
                    placement="top"
                  >
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <Form.Item
                        name="doubleOptIn"
                        valuePropName="checked"
                        data-cy="switch-doubleOptIn"
                      >
                        <StyledSwitch
                          checked={form.getFieldValue('doubleOptIn')}
                          onChange={(value) => form.setFieldValue('doubleOptIn', value)}
                          data-cy="configuration-new-widget-double-opt-in"
                        />
                      </Form.Item>
                      <span style={{ marginLeft: '10px' }}>
                        Activate Double-Opt-In (recommended)
                      </span>
                    </div>
                  </Tooltip>
                </FormWrapper>
                <ConsentCheck>
                  <Checkbox
                    style={{ fontWeight: 400 }}
                    onChange={(e) => setTermsAndConditions(e.target.checked)}
                  >
                    I hereby confirm that I have read and accept the{' '}
                    <a
                      href="https://usercentrics.com/terms-and-conditions/?deviceId=449c87a0-1531-43f5-be9a-f99732c248d5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </a>{' '}
                    /{' '}
                    <a
                      href="https://usercentrics.com/terms-and-conditions-usa/?deviceId=449c87a0-1531-43f5-be9a-f99732c248d5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions (USA)
                    </a>{' '}
                    as well as the{' '}
                    <a
                      href="https://usercentrics.com/wp-content/uploads/2023/08/Final_Joint-Product-DPA_UC.pdf?deviceId=449c87a0-1531-43f5-be9a-f99732c248d5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Data Processing Agreement
                    </a>{' '}
                    of the Usercentrics Consent Management Platform.
                  </Checkbox>
                </ConsentCheck>
              </div>
            </FlexWrapper>
          </Form>
        </Content>
      )}
    </Wrapper>
  );
};

export default Step1;
