import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 40px;
  padding-top: 0;
  width: 100%;
`;

export const Container = styled.div`
  width: 840px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  padding: 20px;

  & p {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 12px;
    color: ${(props) => props.theme.colors.primaryIcons};
    font-weight: 400;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 20px;
  padding: 17px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.darkGray};
  cursor: pointer;

  & a {
    margin-left: 40px;
    color: ${(props) => props.theme.colors.primary};
  }

  & span {
    border-radius: 4;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.colors.primaryText};
  }
`;

export const TagContainer = styled.div`
  height: 32px;
  border-right: 1px solid ${(props) => props.theme.colors.gray};
  margin-right: 12px;
  padding-right: 4px;
  display: flex;
  align-items: center;
  & .ant-tag {
    height: 28px;
    display: inline-flex;
    align-items: center;
  }

  & .ant-tag-green {
    background: #edf6f3;
    color: #17855e;
    border: 1px solid #17855e1a;
  }
`;

export const Description = styled.div`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryIcons};
`;

export const PageTitle = styled.h1`
  padding: 32px 40px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.primaryText};
  font-weight: bold;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primaryText};
  margin-bottom: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MaxMessageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

export const KeyContentWrapper = styled.div`
  height: 72px;
  margin-top: 20px;
  padding: 0 16px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;

  & button {
    padding: 8px;
  }
`;

export const KeyIconContainer = styled.div`
  margin-right: 16px;
`;

export const InfoDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CreatedAtContainer = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.primaryIcons};
  font-weight: 400;
`;

export const NoKeysContainer = styled.div`
  background-color: ${(props) => props.theme.colors.darkGray};
  height: 72px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.silver};
`;
