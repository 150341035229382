import React from 'react';
import { Description, Title } from 'shared-components/ModalsContent';
import { Google as GoogleIcon } from 'components/Icons';
import Button from 'components/Button/Button';

interface IProps {
  googleAccount: string | null;
  setGoogleAccount: (value: string | null) => void;
  login: () => void;
}

export default ({ googleAccount, setGoogleAccount, login }: IProps) => {
  return (
    <div>
      <Title>Establish connection with your Google Ads account</Title>
      <Description>
        Please login into your Google account that has an access to the corresponding Google Ads
        profile.
      </Description>
      {googleAccount ? (
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontWeight: 'bold' }}>You are logged in as {googleAccount}.</p>
          <Button type="white" onClick={() => setGoogleAccount(null)} style={{ margin: 'auto' }}>
            Change Account
          </Button>
        </div>
      ) : (
        <Button type="white" onClick={() => login()} style={{ margin: 'auto' }}>
          <GoogleIcon /> Sign in with Google
        </Button>
      )}
    </div>
  );
};
