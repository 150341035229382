import React from 'react';
import CodeSnippet from '..';

const IntegrationViaPrivateKey = ({
  apiKey,
  pacId,
  widgetId,
  optionId,
}: {
  apiKey: string;
  pacId: string;
  widgetId: string;
  optionId: string;
}) => (
  <CodeSnippet
    copyContent={`url = 'https://preference-api.preference-management.usercentrics.eu/public/loyal/preferences'
headers = {
'accept': 'application/json',
'X-API-KEY': '${apiKey}',
'Content-Type': 'application/json'
}
data = {
"pacId": "${pacId}",
"widgetId": "${widgetId}",
"userId": "[USER_EMAIL]",  # Replace with actual user email
"answers": [
"${optionId}"  # Replace with actual option UUID
]
}
response = requests.post(url, headers=headers, json=data)`}
  >
    url =
    &apos;https://preference-api.preference-management.usercentrics.eu/public/loyal/preferences&apos;
    <br />
    {'headers = {'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&apos;accept&apos;: &apos;application/json&apos;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{"'X-API-KEY': "}
    <strong>{`'${apiKey}'`}</strong>,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&apos;Content-Type&apos;: &apos;application/json&apos;,
    <br />
    {'}'}
    <br />
    {'data = {'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{'"pacId": '}
    <strong>{`"${pacId}"`}</strong>,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{'"widgetId": '}
    <strong>{`"${widgetId}"`}</strong>,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&quot;userId&quot;: <strong>&quot;[USER_EMAIL]&quot;</strong>, # Replace
    with actual user email
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&quot;answers&quot;: [
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <strong>{`"${optionId}"`}</strong># Replace with actual option UUID
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;]
    <br />
    {'}'}
    <br />
    response = requests.post(url, headers=headers, json=data)
  </CodeSnippet>
);

export default IntegrationViaPrivateKey;
