import React from 'react';
import { Layout, Typography, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';

import CodeSnippet from 'components/CodeSnippet';
import Button from 'components/Button/Button';
import GenerateDataCollectorScript from 'components/CodeSnippet/GenerateDataCollectorScript';

import { OnboardingStorageService } from 'lib/services/onboardingStorageService';

import { StyledLogo, Wrapper, Sidebar, StyledText, FlexWrapper, FormWrapper } from './styled';

const { Content } = Layout;
const { Title } = Typography;

const Step3 = () => {
  const navigate = useNavigate();
  const result = OnboardingStorageService.getOnboardingResults();
  const selectors = OnboardingStorageService.getStep2Data();

  const handleAdminRedirect = () => {
    navigate('/configurations');
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Setup your Preference Center</StyledText>
          <Title level={2}>Let&apos;s create a configuration</Title>
          <span>
            A configuration represents a complete setup of your Preference Center including the
            widget that allows your users to manage their consent decisions. This Preference Center
            is available as a stand-alone page and accessible anytime for end users via
            authentication with email flow.
          </span>
        </div>
      </Sidebar>

      <Content style={{ padding: '40px', background: '#fff', overflow: 'scroll' }}>
        <div>
          <Title level={2}>Create Configuration</Title>
          <FlexWrapper>
            <div>
              <h3>Setup is complete!</h3>
            </div>
            <FormWrapper>
              <Title level={5}>
                Your configuration is ready to use! Now proceed with 2 simple steps to put your
                Preference Manager to work!
              </Title>

              <div style={{ marginBottom: '20px' }} data-cy="generate-data-collector-script-block">
                <Title level={5}>
                  Step 1. Please select this code and place it on the page where your form is
                  located
                </Title>
                <GenerateDataCollectorScript
                  widgetUuid={result?.widgetUuid}
                  pacUuid={result?.pacUuid}
                  publicApiKey={result?.publicApiKey}
                  optionUuid={result?.optionUuid}
                  consentSelector={selectors?.consentSelector}
                  userIdSelector={selectors?.userIdSelector}
                  formSelector={selectors?.formSelector}
                />
              </div>

              <div style={{ marginBottom: '20px' }} data-cy="link-to-preference-management-block">
                <Title level={5}>
                  Step 2. Put a link to Preference Center page on your website:
                </Title>
                <CodeSnippet
                  copyContent={`https://app-preference.preference-management.usercentrics.eu/login/${result.pacUuid}?lng=[LANG_CODE]`}
                >
                  https://app-preference.preference-management.usercentrics.eu/login/
                  <strong>{result.pacUuid}</strong>
                  ?lng=
                  <Tooltip
                    title="Optional parameter to display the Preference Center in specific language. The value is a language code in ISO 639-1 format (i.e. “en”, “de”, “it”, etc)."
                    placement="right"
                  >
                    <strong>[LANG_CODE]</strong>
                  </Tooltip>
                </CodeSnippet>
                <Title level={5} style={{ marginTop: '10px' }}>
                  ... or adjust the Preference Center configuration, its Look & Feel using our Admin
                  Interface.
                </Title>
              </div>

              <Button type="primary" onClick={handleAdminRedirect} data-cy="button-admin-interface">
                Proceed to Admin Interface
              </Button>
            </FormWrapper>
          </FlexWrapper>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Step3;
